<div id="appointment-component" #appointmentsSection class="cbw-appointment-wrapper">
  @if (hasQualificationQuestions && !hasQualificationQuestionsAnswered) {
    <!-- Fullscreen overlay qualification questions  -->
    <app-qualification-question
      [(appointmentState)]="appointmentState"
      [qualificationQuestions]="qualificationQuestions"
      [(qualificationQuestionsValues)]="qualificationQuestionsValues"
      [(userState)]="userState"
      [globals]="globals"
      [partner]="partner"
      [lang]="lang"
      [isFrame]="isFrame"
      [widgetTemplates]="widgetTemplates"
      [qualificationQuestionLSNameKey]="QUALIFICATIONQUESTION_LS_NAME"
      [supportedWidgetLanguages]="partner?.supported_widget_languages"
      [langSwitcher]="langSwitcher"
      (submitted)="submitQualificationQuestions($event)"
    />
  }

  <div [ngClass]="{ 'd-none': hasQualificationQuestions && !hasQualificationQuestionsAnswered }">
    <!-- Fullscreen overlay for saferpay payment gateway to when payment is ongoing  -->
    @if (showFullscreenOverlay) {
      <div class="overlay text-center">
        <div class="content">
          <p
            class="font-weight-light"
            [innerHtml]="('summary_page_translations.saferpay_payment_overlay' | translate) | trustHtml"
          ></p>

          <button
            #button
            class="btn btn-lg btn-warning mt-2"
            (click)="closeSaferpayOverlay()"
          >
            {{ 'common.close' | translate }}
          </button>
        </div>
      </div>
    }

    @if (booked) {
      <div>
        <app-finalpage
          [lang]="lang"
          [bookingErrorMessage]="bookingErrorMessage"
          [errTitle]="'appointments.finalpage.errorTitle' | translate"
          [errMsg]="errorMessage"
          [successTitleTemplate]="successTitleTemplate"
          [successMsg]="successMessage"
          [success]="finalPage"
          [pageType]="appointmentConstant.APPOINTMENT"
          [widgetBookingInfo]="widgetBookingInfo"
          [manageAppointmentNowTemplate]="manageAppointmentNowTemplate"
          [downloadBookingDetailTemplate]="downloadBookingDetailTemplate"
          [successFullBookings]="successFullBookings"
        />

        <div class="additional-message mt-4">
          @if (this.conferenceState?.uuid === undefined) {
            @if (hideBookingAgainButton()) {
              <calenso-button
                id="cbw-book-next-appointment-button"
                #button
                (clickEvent)="booked = false; bookAppointmentAgain();"
                [widgetColorConf]="widgetColorConf"
                text="{{ 'appointments.finalpage.bookNextAppointment' | translate }}"
              />
            }
            @if (
              partnerService.hasFeature('is_external_success_page_forwarding_enabled') &&
              partner?.is_redirect_after_booking_enabled &&
              partner?.successful_booking_redirect_url &&
              partner?.redirect_after_booking_action === bookingRedirectionViews.BOOKING_SUCCESS_VIEW &&
              !globals.isInternal
            ) {
              <calenso-button
                id="cbw-redirect-to-external-url-button"
                #button
                (clickEvent)="redirectToExternalUrl();"
                customCss="mt-2"
                [widgetColorConf]="widgetColorConf"
                text="{{ redirectionButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
              />
            }

            @if (!finalPage) {
              <calenso-button
                id="cbw-book-appointment-again-button"
                #button
                (clickEvent)="booked = false; bookAppointmentAgain();"
                [widgetColorConf]="widgetColorConf"
                text="{{ 'appointments.finalpage.bookAppointmentAgain' | translate }}"
              />
            }
          }

          @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
            <div class="mt-4 mb-4">
              <img
                alt="Calenso Logo"
                src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                width="100"
              />
            </div>
          }
        </div>
      </div>
    }

    <!--Workers Loader-->
    <ng-template #noworkers>
      @if (noWorkers) {
        <div>
          <br>
          <app-alert type="warning" text="{{ 'appointments.error.noworkers' | translate }}" />
        </div>
      } @else {
        @if (!noAppointments) {
          <app-loader [loaderType]="appointmentConstant.WORKER_LOADER"/>
        }
      }
    </ng-template>

    @if (!booked) {
      <form #appointmentForm="ngForm" onclick="void(0);">
        <div class="wizard-header" onclick="void(0);">
          <h3 class="wizard-title"></h3>
        </div>

        <div class="wizard-navigation" onclick="void(0);">
          @if (formReseted && !noWorkers) {
            <div>
              <app-alert (click)="formReseted = false" text="{{ 'common.resetSuccess' | translate }}" />
            </div>
          }
          <app-appointment-navigation-bar
            (navigateToEvent)="navigateTo($event)"
            [(isServicesTabDisabled)]="isServicesTabDisabled"
            [appointmentState]="appointmentState"
            [cart]="cart"
            [hideResourceStep]="partner.hide_resource_step === 1 || hideResourceStep || partner.is_round_robin_feature_enabled === 1"
            [hideMeetingTypesStep]="hideMeetingTypesStep"
            [showAds]="showAds"
            [viewMode]="viewMode"
            [widgetColorConf]="widgetColorConf"
            [workersAvailable]="workersAvailable"
            [partner]="partner"
            [lang]="lang"
            [globals]="globals"
            [widgetTemplates]="widgetTemplates"
            [supportedWidgetLanguages]="partner?.supported_widget_languages"
            [langSwitcher]="langSwitcher"
            [appointmentFormStatus]="appointmentForm.valid"
          />
        </div>

        @if (showWorkerIsNotBookableError) {
          <app-new-alert
            type="warning"
            title="{{ 'common.configuration_error' | translate }}"
            subTitle="{{ 'common.resource_is_not_bookable' | translate }}"
            [date]="now"
            customClass="mt-3 mb-3"
          />
        }

        <div>
          @switch (viewMode) {
            <!-- Tab 1: Customer type (Dynamically) -->
            @case (appointmentConstant.CUSTOMER_TYPE) {
              <app-appointment-customer-profiles
                [partner]="partner"
                [lang]="lang"
                [appointmentState]="appointmentState"
                [widgetColorConf]="widgetColorConf"
                [nextButtonTemplate]="nextButtonTemplate"
                (navigateToEvent)="navigateTo($event.page)"
              />
            }

            <!-- Tab 2: Store and Service selection -->
            @case (appointmentConstant.APPOINTMENT) {
              <app-appointment-list
                (disabledServiceTabEvent)="disabledServiceTab($event)"
                (navigateToEvent)="resetCalendarData();navigateTo($event.page, $event.isFirstTime);"
                (resetFormEvent)="resetForm()"
                (serviceChangeEvent)="onServiceChange($event)"
                (storeSelectedEvent)="onStoreSelected($event)"
                (newStoresEvent)="onNewStoresEvent($event)"
                [(appoinrmentServicesByCat)]="appoinrmentServicesByCat"
                [(appointmentState)]="appointmentState"
                [lang]="lang"
                [selectedStoreZipCode]="selectedStoreZipCode"
                [partner]="partner"
                [stores]="stores"
                [widgetColorConf]="widgetColorConf"
                [widgetConf]="widgetConf"
                [hideStoreSelection]="
                  (selectedStoreId && selectedStoreAvailable && !globals.isInternal) ||
                  (selectedStoreId && hideStoreSelection && globals.isInternal)
                "
                [previousButtonTemplate]="previousButtonTemplate"
                [nextButtonTemplate]="nextButtonTemplate"
                [globals]="globals"
                [widgetTemplates]="widgetTemplates"
                [subscriptionTypeId]="widgetConf.subscription.subscription_type_id"
                [conferenceState]="conferenceState"
              />
            }

            <!-- Tab 3: Worker selection -->
            @case (appointmentConstant.WORKER) {
              <div class="worker-tab">
                <app-appointment-worker-card
                  (navigateToEvent)="navigateTo($event)"
                  (resetFormEvent)="resetForm()"
                  (selectWorkerEvent)="selectWorker($event)"
                  (workerSelectedEvent)="onWorkerSelected()"
                  (workerExceptionEvent)="getExceptionalWorkers($event)"
                  [workerException]="exceptionWorkers"
                  [appointmentState]="appointmentState"
                  [showEgalWer]="partner.showEgalWer"
                  [widgetColorConf]="widgetColorConf"
                  [workersAvailable]="workersAvailable"
                  [lang]="lang"
                  [partner]="partner"
                  [viewMode]="viewMode"
                  [nextButtonTemplate]="nextButtonTemplate"
                  [previousButtonTemplate]="previousButtonTemplate"
                  [widgetTemplates]="widgetTemplates"
                  [subscriptionTypeId]="widgetConf.subscription.subscription_type_id"
                />
              </div>
            }

            <!-- Tab 4: meeting type selection (Dynamically) -->
            @case (appointmentConstant.MEETING_PROVIDERS) {
              <app-appointment-meeting-type
                [lang]="lang"
                [appointmentState]="appointmentState"
                [(userState)]="userState"
                [partner]="partner"
                [widgetTemplates]="widgetTemplates"
                [widgetColorConf]="widgetColorConf"
                [nextButtonTemplate]="nextButtonTemplate"
                [previousButtonTemplate]="previousButtonTemplate"
                [subscriptionTypeId]="widgetConf.subscription.subscription_type_id"
                (navigateToEvent)="navigateTo($event)"
                (hideMeetingTypesStepValue)="getHideMeetingTypesStepValue($event)"
              />
            }

            <!-- Tab 5: Date and Slot selection -->
            @case (appointmentConstant.DATE) {
              <div class="row cb-appointment-date-block">
                <div class="col-sm-12">
                  <h4
                    class="cbw-choose-date-title"
                    [innerHTML]="dateLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang | trustHtml: 'html'"
                  >
                  </h4>
                  @if (+partner.show_widget_calendar_notice === 1) {
                    @if (this.showWidgetCalendarOverwriteNotice.length) {
                      <div class="alert alert-success cbw-calendar-highlight-notice-alert">
                        @for (highlight of showWidgetCalendarOverwriteNotice; track $index) {
                          <div [innerHTML]="highlight | translation: 'calendar_hint_banner_text': lang" ></div>
                        }
                      </div>
                    } @else if (widgetCalendarHighlightsNoticeText && (widgetCalendarHighlightsNoticeText | translation: templateContent.TEMPLATE_CONTENT: lang)?.trim()) {
                      <div class="alert alert-success cbw-calendar-highlight-notice-alert">
                        <div [innerHTML]="(widgetCalendarHighlightsNoticeText | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml: 'html'"></div>
                      </div>
                    }
                  }
                </div>

                <div class="col-lg-6 col-md-6 col-xs-6 col-xxs-12 cbw-calender-picker-wrapper">
                  <app-calendar-picker
                    (dayClickedEvent)="dayClicked($event)"
                    (markExceptionDaysEvent)="markExceptionDays($event)"
                    (viewDateChangedEvent)="viewDateChanged($event)"
                    [(viewDate)]="viewDate"
                    [calendarPreselectedDate]="calendarPreselectedDate"
                    [calendarLoaded]="calendarLoaded"
                    [calendarSlotsCountList]="calendarSlotsCountList"
                    [days]="days"
                    [disablePrev]="disablePrev"
                    [futureBookingThreshold]="partner.future_booking_threshold_appointments"
                    [noFreeDates]="noAppointments"
                    [widgetColorConf]="widgetColorConf"
                    [workerSetter]="selectedWorker"
                    [lang]="lang"
                    [partner]="partner"
                    [conferenceState]="conferenceState"
                    [calendarHighlightData]="calendarHighlightData"
                    [appointmentState]="appointmentState"
                    [globals]="globals"
                  />

                  @if (partner.show_worker_selection_on_date_step === 1) {
                    <div class="show-workers-list-note cbw-show-workers-list-note">
                      {{ 'appointments.step3.workerSelectLinkPrefix' | translate }}
                      <a href="javascript:void(0);" (click)="showWorkersListOnDateStepFn()">
                        {{ (showWorkersListOnDateStep ? 'appointments.step3.workerSelectHideAllLinkPostfix' : 'appointments.step3.workerSelectShowAllLinkPostfix') | translate }}
                      </a>
                    </div>
                  }

                  @if (globals.debugMode) {
                    <div class="troubleshoot-mode-toggle cbw-troubleshoot-mode-toggle">
                      @if (!troubleshootingModeEnabled) {
                        {{ 'appointments.step3.troubleshooting_mode_note_pre' | translate }}
                        <a href="javascript:void(0);" (click)="toggleTroubleshootingMode()">{{ 'appointments.step3.troubleshooting_mode_link' | translate }}</a>
                        {{ 'appointments.step3.troubleshooting_mode_note_post' | translate }}
                      } @else {
                        {{ 'appointments.step3.turn_off_troubleshooting' | translate }}
                        <a href="javascript:void(0);" (click)="toggleTroubleshootingMode()">{{ 'appointments.step3.troubleshooting_mode_link' | translate }}</a>.
                      }
                    </div>

                    @if (troubleshootingModeEnabled && debugCommoNotes?.length) {
                      <div class="troubleshoot-mode-commom-notes">
                        <b>{{ 'appointments.step3.common_notes' | translate }}:</b>
                        <ul class="troubleshoot-mode-commom-notes-list">
                          @for (note of debugCommoNotes; track note) {
                            <li
                              class="troubleshoot-mode-commom-note"
                              [innerHTML]="note | trustHtml: 'html'"
                            ></li>
                          }
                        </ul>
                      </div>
                    }
                  }
                </div>

                <div class="col-lg-6 col-md-6 col-xs-6 col-xxs-12 termin-container cbw-appointment-slots-wrapper">
                  @if (showWorkersListOnDateStep) {
                    <div class="worker-selection-on-date-step cbw-worker-selection-on-date-step">
                      <label class="worker-selection-label cbw-worker-selection-label">
                        {{ 'appointments.step3.workerSelectLable' | translate }}
                      </label>
                      <ng-select
                        name="worker_selection_on_date_step"
                        class="wroker-select-on-date-step"
                        [searchFn]="customWorkerSearchFn"
                        [clearable]="false"
                        [items]="workersAvailableWithAnyoneOption"
                        (change)="selectWorker($event?.id, true)"
                      >
                        <ng-template ng-option-tmp let-worker="item">
                          <div class="worker">
                            <div class="avatar-wrapper cbw-avatar-wrapper">
                              <img #image class="avatar cbw-avatar" height="35" width="35"
                                [src]="worker.avatar !== null ? workerAvatarUrl + worker.uuid + '/thumb_' + worker.avatar : baseUrl + '/img/sample-avatar.png'"
                                (error)="image.src= baseUrl + '/img/sample-avatar.png'" />
                            </div>

                            @if (worker.id === 0) {
                              <div>
                                <div class="worker-name cbw-worker-name"
                                  [innerHTML]="(widgetAnyoneLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                                </div>

                                <div class="worker-function cbw-worker-function"
                                  [innerHTML]="(widgetAnyoneDescTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                                </div>
                              </div>
                            }

                            @if (worker.id !== 0) {
                              <div>
                                <div class="worker-name cbw-worker-name">
                                  {{ worker | translation: 'resource_name': lang }}
                                </div>
                                @if (worker.is_resource === 0 && (worker | translation: 'job_title': lang); as jobTitle) {
                                  <div
                                  class="worker-function cbw-worker-function"
                                  [innerHTML]="jobTitle | trustHtml">
                                  </div>
                                }

                                @if (worker.is_resource === 1 && (worker | translation: 'description': lang); as description) {
                                  <div
                                  class="worker-function cbw-worker-function"
                                  [innerHTML]="description | trustHtml"
                                  >
                                  </div>
                                }

                                @if (worker?.attributes?.length) {
                                  @for (attribute of worker.attributes; track attribute.uuid) {
                                    @if (attribute.type === 'select') {
                                      <div class="attribute-container cbw-attribute-container">
                                        <div class="cbw-attribute">
                                          {{ attribute | translation: 'name': lang }}:
                                        </div>
                                        <div class="attribute-value-section">
                                          @for (value of attribute.attributes_values; track value.uuid; let last = $last) {
                                            <div class="attribute-value-img">
                                              @if (value.icon_url !== null) {
                                                <img
                                                  #image
                                                  class="cbw-attribute-icon"
                                                  alt="{{ value.value }}"
                                                  src="{{ value.icon_url }}"
                                                  (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                                                />
                                              }
                                              <div class="cbw-attribute-value">
                                                {{ value | translation: 'value' : lang }}
                                                @if (!last) { | }
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    } @else {
                                      <div class="cbw-attribute cbw-attribute-container">
                                        {{ attribute | translation: 'name': lang }}:
                                        @if (attribute.attributes_values?.length) {
                                          {{ +attribute.attributes_values[0].value === 1 ? ( 'yes' | translate ): ( 'no' | translate ) }}
                                        }
                                      </div>
                                    }
                                  }
                                }
                              </div>
                            }
                          </div>
                        </ng-template>

                        <ng-template ng-label-tmp let-worker="item">
                          <div class="worker">
                            <div class="avatar-wrapper cbw-avatar-wrapper">
                              <img #image class="avatar cbw-avatar" height="35" width="35"
                                [src]="worker.avatar !== null ? workerAvatarUrl + worker.uuid + '/thumb_' + worker.avatar : baseUrl + '/img/sample-avatar.png'"
                                (error)="image.src= baseUrl + '/img/sample-avatar.png'" />
                            </div>

                            @if (worker.id === 0) {
                              <div class="worker-name cbw-worker-name"
                                [innerHTML]="(widgetAnyoneLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                              ></div>
                            } @else {
                              <div class="worker-name cbw-worker-name">
                                {{ worker | translation: 'resource_name': lang }}
                              </div>
                            }
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  }

                  @if (!this.appointmentState?.date && showAppointmentSlotGhostElement !== 1 && showAppointmentSlotGhostElement !== 2 && calendarDate) {
                    <div class="alert cbw-date-select-hint-alert">
                      {{ selectDayInCalendarMsgTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                    </div>
                  }

                  @if (showAppointmentSlotGhostElement === 1) {
                    <div class="skeleton-box-card">
                      <div class="row">
                        @if (widgetGroups.length) {
                          @for (slotGhost of slotGhostElementsCount; track slotGhost) {
                            <div class="d-none d-sm-block col-sm-3 mb-3">
                              <div class="d-flex stats-card">
                                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                                  <div class="w-100">
                                    <div class="stat-box-1 mb-1"></div>
                                    <div class="stat-box-1"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        }

                        @for (appointmentSlotGhostElement of appointmentSlotGhostElements; track appointmentSlotGhostElement) {
                          <div class="col-xs-12 col-sm-6 mb-3" [ngClass]="{'normal-slots': !widgetGroups.length}">
                            <div class="d-flex stats-card">
                              <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                                <div class="w-100">
                                  <div class="stat-box-1"></div>
                                  <div class="stat-box mt-1"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }

                  @if (showAppointmentSlotGhostElement === 2) {
                    <!-- Reservation realted errors start -->
                    @if (slotAlreadyReservedByOtherError) {
                      <div class="reservation-error-message cbw-reservation-error-message">
                        {{ 'alert_messages.slot_already_reserved_by_other_error' | translate }}
                      </div>
                    }
                    <!-- Reservation realted errors end -->

                    @if (showBookableDateNotAvailableIssue) {
                      @if (!automationBookingDatesLimitReached) {
                        <div class="no-bookable-error-message">
                          {{ noBookableDatesTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                        </div>
                      }

                      @if (automationBookingDatesLimitReached) {
                        <div class="no-bookable-error-message">
                          {{ 'alert_messages.no_bookable_dates_reached_message' | translate: { month: (viewDate | date:'MMMM' | translate) } }}
                        </div>
                      }

                      @if (!automationBookingDatesLimitReached) {
                        <div class="mt-2">
                          <calenso-button
                            #button
                            (clickEvent)="autoLoadBookableDates()"
                            [widgetColorConf]="widgetColorConf"
                            placement="left"
                            text="{{ yesSearchButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
                          />
                        </div>
                      }
                    }

                    @if (!showBookableDateNotAvailableIssue && appointmentState?.date) {
                      <app-appointment-card
                        [appointmentState]="appointmentState"
                        [cart]="cart"
                        [(freeAppointments)]="freeAppointments"
                        [(noAppointments)]="noAppointments"
                        [partner]="partner"
                        [lang]="lang"
                        [widgetColorConf]="widgetColorConf"
                        [showEgalWer]="partner.showEgalWer"
                        [freeAppointmentsCount]="freeAppointmentsCount"
                        [widgetTemplates]="widgetTemplates"
                        [conferenceState]="conferenceState"
                        [showAppointmentSlots]="showAppointmentSlots"
                        (updateCartEvent)="updateCart($event)"
                        (gotoNextPageEvent)="reserveAppointments()"
                      />
                    }
                  }
                </div>
              </div>

              <div class="wizard-footer">
                <div class="row margin-x-0">
                  <div class="col-4 d-flex align-items-center justify-content-start">
                    @if (
                      widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id &&
                      (!globals.isInternal || partner?.widget_show_lead_creation_button !== 1 || partner.enable_store_postcode_feature !== 1)
                    ) {
                      <div class="d-md-none">
                        <img src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}" alt="Calenso Logo" width="100">
                      </div>
                    }

                    @if (globals.isInternal && partner?.widget_show_lead_creation_button === 1 && partner.enable_store_postcode_feature === 1) {
                      <calenso-button
                        #button
                        text="{{ 'appointments.step1.expert_cap_button_label' | translate }}"
                        (clickEvent)="configureCustomerLinkEventHandler()"
                        [widgetColorConf]="widgetColorConf"
                      />
                    }
                  </div>

                  <div class="col-4 d-flex align-items-center justify-content-center">
                    @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                      <div class="d-none d-md-block">
                        <img src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}" alt="Calenso Logo" width="100">
                      </div>
                    }
                  </div>

                  <div class="col-4 d-flex align-items-center justify-content-end next-btn-custom-right-padding">
                    <calenso-button
                      id="cbw-prev-button"
                      #button
                      (clickEvent)="backButtonClicked = true; navigateTo(appointmentConstant.WORKER)"
                      [disabled]="disableDatePagePreviousButton"
                      [widgetColorConf]="widgetColorConf"
                      placement="left"
                      [text]="previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                    />

                    <calenso-button
                      id="cbw-next-button"
                      class="ms-2"
                      #button
                      (clickEvent)="reserveAppointments()"
                      [isloading]="reservingAppointment"
                      [disabled]="cart.length <= 0"
                      [widgetColorConf]="widgetColorConf"
                      placement="right"
                      [text]="nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                    />
                  </div>
                </div>
              </div>
            }

            <!-- Tab 6: Personal-Data & Cart-Overview -->
            @case (appointmentConstant.PERSONAL_INFO) {
              <div class="summary-tab">
                <div class="row inline-block cb-appointment-summary-block">
                  <!-- Start of personal-data container  -->
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 cbw-personal-data-wrapper">
                    @if (!isCustomFieldsLoaded) {
                      <app-cw-new-card
                        [showBody]="true"
                        [showHeader]="true"
                        customCardClass="mb-4"
                      >
                        <div header>
                          <app-cw-card-header title="" subtitle="" />
                        </div>
                        <div body class="my-4">
                          <div class="skeleton-box-card">
                            <div class="row">
                              @for (appointmentSlotGhostElement of appointmentSlotGhostElements; track appointmentSlotGhostElement) {
                                <div class="col-md-12 mb-4">
                                  <div class="d-flex stats-card">
                                    <div class="skeleton-animation w-100 d-flex justify-content-center flex-column">
                                      <div>
                                        <div class="stat-box-2"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </app-cw-new-card>
                    }

                    @if (cart?.length && isCustomFieldsLoaded) {
                      <app-personal-data-form
                        (updateCustomerSelectedEvent)="updateCustomerSelected($event)"
                        (updateUserStateEvent)="updateUserState($event)"
                        [(customFieldValues)]="customFieldValues"
                        [(userState)]="userState"
                        [customFields]="appointmentCustomFields"
                        [globals]="globals"
                        [hideLoginOnBookingSite]="partner.hide_login_on_bookingsite"
                        [lang]="lang"
                        [partner]="partner"
                        [type]="appointmentConstant.APPOINTMENT"
                        [courtesyForm]="courtesyForm"
                        [appointmentCart]="cart"
                        [widgetTemplates]="widgetTemplates"
                        [widgetColorConf]="widgetColorConf"
                        [customFieldValuesLSNameKey]="CUSTOMFIELDS_LS_NAME"
                        [selectedJsonCustomFields]="selectedJsonCustomFields"
                        [selectedJsonCustomerDetail]="selectedJsonCustomerDetail"
                        [userDataLSNameKey]="USER_LS_NAME"
                        [meeting_type_id]="meeting_type_id"
                        [customer_notification_preference]="customer_notification_preference"
                        [appointmentState]="appointmentState"
                        [stores]="stores"
                        [conferenceState]="conferenceState"
                        [brokerState]="brokerState"
                      />
                    }
                  </div>
                  <!-- End of personal-data container component -->

                  <!-- Start of payment method container -->
                  @if (partner?.split_personal_form_and_summary_in_widget === 0 && (totalCartPrice > 0 && isServiceOnlinePaymentEnabled === 1)) {
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <ng-container *ngTemplateOutlet="paymentMethodsTemplate" />
                    </div>
                  }
                  <!-- End of payment method container -->

                  <!-- Start of cart container -> supposed to be its own component -->
                  @if (partner?.split_personal_form_and_summary_in_widget === 0 || globals.isInternal) {
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 cbw-appointment-cart-overview-wrapper">
                      <ng-container *ngTemplateOutlet="cartOverviewTemplate; context: { mode: appointmentConstant.PERSONAL_INFO}"  />
                    </div>
                  }
                  <!-- End of cart container component -->
                </div>

                <!-- Tab 4 Pagination -->
                <div class="row margin-x-0 final-page-buttons-box">
                  @if (partner?.split_personal_form_and_summary_in_widget === 0) {
                    @if (bookingErrorMessage) {
                      <div class="col-sm-12 mt-2">
                      <app-alert [text]="bookingErrorMessage" type="danger"/>
                      </div>
                    }

                    @if (paymentType === paymentTypes.PAYPAL && !paypalOrderDetail) {
                      <div class="col-sm-12 mt-2">
                        <app-alert text="{{ 'summary_page_translations.paypal_warning_msg' | translate }}" type="warning"/>
                      </div>
                    }
                  }

                  <div class="col-sm-12">
                    <div class="wizard-footer clearfix">
                      <div class="row">
                        <div class="col-4 d-flex align-items-center justify-content-start">
                          @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                            <div class="d-md-none">
                              <img
                                alt="Calenso Logo"
                                src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                                width="100"
                              />
                            </div>
                          }
                        </div>

                        <div class="col-4 d-flex align-items-center justify-content-center">
                          @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                            <div class="d-none d-md-block">
                              <img
                                alt="Calenso Logo"
                                src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                                width="100"
                              />
                            </div>
                          }
                        </div>

                        <div class="col-4 d-flex align-items-center justify-content-end next-btn-custom-right-padding">
                          <calenso-button
                            id="cbw-prev-button"
                            #button
                            (clickEvent)="navigateTo(appointmentConstant.DATE)"
                            [widgetColorConf]="widgetColorConf"
                            placement="left"
                            [text]="previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                          />

                          @if (partner?.split_personal_form_and_summary_in_widget === 1) {
                            <calenso-button
                              id="cbw-next-button"
                              class="ms-2"
                              #button
                              (clickEvent)="validatePersonalFormAndNavigateToSummaryStep()"
                              [widgetColorConf]="widgetColorConf"
                              placement="right"
                              [text]="nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                            />
                          } @else {
                            @if (!hideBookingButton) {
                              @if (this.showSaferpayButtonLink) {
                                <calenso-button
                                  class="ms-2"
                                  #button
                                  id="cbw-booking-finish"
                                  (clickEvent)="payUsingSaferpay()"
                                  [widgetColorConf]="widgetColorConf"
                                  placement="right"
                                  [isloading]="bookButtonLoader"
                                  [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                />
                              } @else {
                                <calenso-button
                                  class="ms-2"
                                  #button
                                  id="cbw-booking-finish"
                                  (clickEvent)="book()"
                                  [disabled]="disableBookingButton"
                                  [widgetColorConf]="widgetColorConf"
                                  placement="right"
                                  [isloading]="bookButtonLoader"
                                  [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                />
                              }
                            }
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <!-- Tab 7: Summary -->
            @case (appointmentConstant.SUMMARY) {
              @if (showSummaryDetails) {
                <!-- Start of summary details sections -->
                <div class="booking-still-open-note cbw-booking-still-open-note">
                  <app-alert
                    [text]="bookingIncompleteNoteOnSummaryStep | translation: templateContent.TEMPLATE_CONTENT: lang"
                    type="warning"
                  />
                </div>

                <div class="appointment-data-review-block cbw-appointment-data-review-block">
                  <div class="appointment-personal-info-block cbw-appointment-personal-info-block">
                    <app-cw-new-card showHeader="true">
                      <div header class="heading cbw-heading">
                        <calio-mat-icon class="icons cbw-person-icon" iconType="person-f" height="26px" width="26px" />
                        <div class="heading-text" [innerHTML]="(summaryPagePersonalInfoHeading | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"></div>
                        <calio-mat-icon
                          class="edit-personal-info icons cbw-edit-icon cursor-pointer"
                          iconType="edit"
                          height="20px"
                          width="20px"
                          (click)="navigateTo(appointmentConstant.PERSONAL_INFO)"
                        />
                      </div>

                      <div body class="personal-info cbw-personal-info">
                        <!-- Gender -->
                        @if (summaryDetails?.gender) {
                          <div class="details cbw-details">
                            @if (summaryDetails.gender === 'm') {
                              {{ 'summary_page_translations.mr' | translate }}
                            } @else if (summaryDetails.gender === 'f') {
                              {{ 'summary_page_translations.ms' | translate }}
                            } @else {
                              {{ 'summary_page_translations.other' | translate }}
                            }
                          </div>
                        }

                        <!-- Prename & Lastname -->
                        @if (summaryDetails?.prename || summaryDetails?.lastname) {
                          <div class="details cbw-details">
                            <!-- Title -->
                            @if (summaryDetails?.title) {
                              @switch(summaryDetails.title) {
                                @case (1) {
                                  <span>{{ "Dr. " }}</span>
                                }
                                @case (2) {
                                  <span>{{ "Dr. med. " }}</span>
                                }
                                @case (3) {
                                  <span>{{ "Prof. " }}</span>
                                }
                              }
                            }

                            <!-- Prename -->
                            @if (summaryDetails?.prename) {
                              <span [innerHTML]="summaryDetails.prename + ' ' | trustHtml: 'html'"></span>
                            }

                            <!-- Lastname -->
                            @if (summaryDetails?.lastname) {
                              <span [innerHTML]="summaryDetails.lastname | trustHtml: 'html'"></span>
                            }
                          </div>
                        }

                        <!-- Adderess -->
                        @if (summaryDetails?.street || summaryDetails?.zip || summaryDetails?.city || summaryDetails?.country) {
                          <div class="details address cbw-details">
                            <span class="cbw-summary-location-address">
                              @if (summaryDetails?.street) {
                                <span class="cbw-summary-location-street">{{ summaryDetails.street }}@if (summaryDetails?.zip) {, }</span>
                              }

                              @if (summaryDetails?.zip || summaryDetails?.city) {
                                <div>
                                  @if (summaryDetails?.zip) {
                                    <span class="cbw-summary-location-zip">{{ summaryDetails.zip }} </span>
                                  }
                                  @if (summaryDetails?.city) {
                                    <span class="cbw-summary-location-city">{{ summaryDetails.city }}@if (summaryDetails?.country) {, }</span>
                                  }
                                </div>
                              }

                              @if (summaryDetails?.country) {
                                <div>
                                  <span class="cbw-summary-location-country">{{ summaryDetails.country | translation: 'name' : lang }}</span>
                                </div>
                                }
                            </span>
                          </div>
                        }

                        <!-- Email -->
                        @if (summaryDetails?.email) {
                          <div class="details cbw-details">
                            <span>{{ 'customer.eMail.label' | translate }}</span>:
                            <span [innerHTML]="summaryDetails.email | trustHtml: 'html'"></span>
                          </div>
                        }

                        <!-- Mobile -->
                        @if (summaryDetails?.mobile) {
                          <div class="details cbw-details">
                            <span
                              [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 263, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                            ></span>:
                            <span [innerHTML]="summaryDetails.mobile | trustHtml: 'html'"></span>
                          </div>
                        }

                        <!-- Phone -->
                        @if (summaryDetails?.phone) {
                          <div class="details cbw-details">
                            <span
                              [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 223, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                            ></span>:
                            <span [innerHTML]="summaryDetails.phone | trustHtml: 'html'"></span>
                          </div>
                        }

                        @if (appointmentCustomFields?.length) {
                          <div class="booking-questions cbw-booking-questions">
                            @for (customField of appointmentCustomFields; track customField) {
                              @if (
                                summaryDetails.customFieldValues?.[customField.id] &&
                                (summaryDetails.customFieldValues?.[customField.id]?.options?.length || summaryDetails.customFieldValues?.[customField.id]?.value)
                              ) {
                                <ng-container *ngTemplateOutlet="customFieldSummaryView; context: { customField: customField }" />
                                @if (customField?.customFieldDependencies?.length) {
                                  @for (dependentCustomField of customField.customFieldDependencies; track dependentCustomField) {
                                    @if (
                                      summaryDetails.customFieldValues?.[dependentCustomField.id] &&
                                      (summaryDetails.customFieldValues?.[dependentCustomField.id]?.options || summaryDetails.customFieldValues?.[dependentCustomField.id]?.value)
                                    ) {
                                      <ng-container *ngTemplateOutlet="customFieldSummaryView; context: { customField: dependentCustomField }" />
                                    }
                                  }
                                }
                              }
                            }
                          </div>

                          <ng-template #customFieldSummaryView let-customField="customField">
                            @if (customField.type !== this.bookingCustomFieldTypes.INFORMATION) {
                              <div class="question cbw-question">
                                <span class="label cbw-label">
                                  @if (!(customField | translation: 'title': lang)) {
                                    <span [innerHTML]="(customField | translation: 'label': lang) | replaceQuestionmarkFromEndOfString | trustHtml: 'html' "></span>:
                                  } @else {
                                    <span [innerHTML]="(customField | translation: 'title': lang) | replaceQuestionmarkFromEndOfString | trustHtml: 'html'"></span>
                                    <span class="light-label" [innerHTML]="(' (' + (customField | translation: 'label': lang) + ')') | replaceQuestionmarkFromEndOfString | trustHtml: 'html'"></span>:
                                  }
                                </span>
                                @if ([bookingCustomFieldTypes.SELECT, bookingCustomFieldTypes.RADIO, bookingCustomFieldTypes.IMAGE_SELECT,
                                  bookingCustomFieldTypes.CARD_SELECT].includes(customField.type)) {
                                  <span class="value cbw-value">
                                    @for (option of summaryDetails.customFieldValues[customField.id].options; track option; let i = $index; let l = $count) {
                                      @if (option?.title) {
                                        <span>
                                          {{ option | translation: 'title': lang }}
                                          ({{ option | translation: 'label': lang }})@if ((l-(i+1)) > 0) {<span>, </span>}
                                        </span>
                                      } @else {
                                        <span>
                                          {{ option | translation: 'label': lang }}@if ((l-(i+1)) > 0) {<span>, </span>}
                                        </span>
                                      }
                                    }
                                  </span>
                                } @else if (customField.type === this.bookingCustomFieldTypes.CHECKBOX) {
                                  <span class="value cbw-value">
                                    {{ ['true', '1', 1, true].includes(summaryDetails.customFieldValues[customField.id].value) ? ('yes' | translate) : ('no' | translate)  }}
                                  </span>
                                } @else if (![
                                  bookingCustomFieldTypes.WORKER_SELECT,
                                  bookingCustomFieldTypes.SELECT,
                                  bookingCustomFieldTypes.CHECKBOX,
                                  bookingCustomFieldTypes.FILE,
                                  bookingCustomFieldTypes.RADIO,
                                  bookingCustomFieldTypes.IMAGE_SELECT,
                                  bookingCustomFieldTypes.CARD_SELECT,
                                  bookingCustomFieldTypes.NUMBER_SELECT
                                  ].includes(customField?.type)) {
                                  <span class="value cbw-value">
                                    {{ summaryDetails.customFieldValues[customField.id].value }}
                                  </span>
                                } @else if (customField.type === bookingCustomFieldTypes.NUMBER_SELECT) {
                                  <span class="value cbw-value">
                                    @for (option of summaryDetails.customFieldValues[customField.id].options; track option; let i = $index;) {
                                      <span>
                                        @if (option?.option?.title) {
                                          <span>
                                            {{ option?.option | translation: 'title': lang}}
                                            ({{ option?.option | translation: 'label': lang}}):
                                          </span>
                                        } @else {
                                          <span>
                                            {{ option?.option | translation: 'label': lang}}:
                                          </span>
                                        }
                                        <span class="ps-1">
                                          {{ option?.value }}
                                        </span>
                                      </span>
                                    }
                                  </span>
                                }
                              </div>
                            }
                          </ng-template>
                        }
                      </div>
                    </app-cw-new-card>
                  </div>

                  <div class="cart-summary-block cbw-cart-summary-block">
                    <app-cw-new-card showHeader="true">
                      <div header class="heading cbw-heading">
                        <calio-mat-icon class="icons cbw-event-icon" iconType="event-f" height="26px" width="26px" />
                        <div class="heading-text" [innerHTML]="(summaryPageCartDetailsHeading | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"></div>
                      </div>

                      <div body class="personal-info cbw-personal-info">
                        <ng-container *ngTemplateOutlet="cartOverviewTemplate; context: { mode: appointmentConstant.SUMMARY }" />
                      </div>
                    </app-cw-new-card>
                  </div>
                </div>
                <!-- End of summary details sections -->
              }

              <!-- Start of payment method container -->
              @if (totalCartPrice > 0 && isServiceOnlinePaymentEnabled === 1) {
                <div class="mt-4 summary-payment-methods">
                  <ng-container *ngTemplateOutlet="paymentMethodsTemplate" />
                </div>
              }
              <!-- End of payment method container -->

              <div class="row margin-x-0 final-page-buttons-box">
                @if (bookingErrorMessage) {
                  <div class="col-sm-12 mt-2">
                    <app-alert [text]="bookingErrorMessage" type="danger"/>
                  </div>
                }

                @if (paymentType === paymentTypes.PAYPAL && !paypalOrderDetail) {
                  <div class="col-sm-12 mt-2">
                    <app-alert text="{{ 'summary_page_translations.paypal_warning_msg' | translate }}" type="warning"/>
                  </div>
                }

                <div class="col-sm-12">
                  <div class="wizard-footer clearfix">
                    <div class="row">
                      <div class="col-4 d-flex align-items-center justify-content-start">
                        @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                          <div class="d-md-none">
                            <img
                              alt="Calenso Logo"
                              src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                              width="100"
                            />
                          </div>
                        }
                      </div>

                      <div class="col-4 d-flex align-items-center justify-content-center">
                        @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                          <div class="d-none d-md-block">
                            <img
                              alt="Calenso Logo"
                              src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
                              width="100"
                            />
                          </div>
                        }
                      </div>

                      <div class="col-4 d-flex align-items-center justify-content-end next-btn-custom-right-padding">
                        <calenso-button
                          id="cbw-prev-button"
                          #button
                          (clickEvent)="navigateTo(appointmentConstant.PERSONAL_INFO)"
                          [widgetColorConf]="widgetColorConf"
                          placement="left"
                          [text]="previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                        />

                         @if (!hideBookingButton) {
                          @if (this.showSaferpayButtonLink) {
                            <calenso-button
                              class="ms-2"
                              #button
                              id="cbw-booking-finish"
                              placement="right"
                              [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                              [widgetColorConf]="widgetColorConf"
                              [isloading]="bookButtonLoader"
                              (clickEvent)="payUsingSaferpay()"
                            />
                          } @else {
                            <calenso-button
                              class="ms-2"
                              #button
                              id="cbw-booking-finish"
                              placement="right"
                              [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                              [widgetColorConf]="widgetColorConf"
                              [isloading]="bookButtonLoader"
                              [disabled]="disableBookingButton"
                              (clickEvent)="book()"
                            />
                          }
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Start of cross selling service -->
              @if (
                partner.show_cross_selling_services_on_summary_step === 1 &&
                crossSellingServices.length
              ) {
                <hr />
                <div class="cross-selling-services-block cbw-cross-selling-services-block">
                  <app-cw-new-card showHeader="true">
                    <div header class="heading cbw-heading">
                      <calio-mat-icon class="icons cbw-event-icon" iconType="plus-f" height="20px" width="20px" style="width: 26px;" />
                      <div class="heading-text"
                        [innerHTML]="(summaryPageCrossServiceHeading | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                      ></div>
                    </div>

                    <div body class="services-list cbw-services-list">
                      <app-cross-selling-services
                        [crossSellingServices]="crossSellingServices"
                        [lang]="lang"
                        [partner]="partner"
                        [widgetColorConf]="widgetColorConf"
                        [addCrossServiceToCartButtonText]="addCrossServiceToCartButtonText"
                        (serviceChangeEvent)="onServiceChange($event); navigateTo(appointmentConstant.WORKER);"
                      />
                    </div>
                  </app-cw-new-card>
                </div>
              }
              <!-- End of cross selling service -->
            }
          }
        </div>
      </form>
    }
  </div>
</div>

<ng-template #paymentMethodsTemplate>
  <app-cw-payment-methods
    [isStripeEnabled]="isStripeEnabled"
    [isOnlinePaymentMandatory]="isOnlinePaymentMandatory"
    [isBexioEnabled]="isBexioEnabled"
    [isStoreEnabled]="isStoreEnabled"
    [isLexOfficePaymentTypeEnabled]="isLexOfficePaymentTypeEnabled"
    [isPaypalEnabled]="isPaypalEnabled"
    [isSaferpayEnabled]="isSaferpayEnabled"
    [(paymentType)]="paymentType"
    [partner]="partner"
    [lang]="lang"
    [widgetType]="widgetConstant.APPOINTMENT"
    [paypalSetting]="paypalSetting"
    [priceAfterCouponReduction]="priceAfterCouponReduction"
    [totalAmount]="totalCartPrice"
    [courtesyForm]="courtesyForm"
    [widgetTemplates]="widgetTemplates"
    [appoinrmentServicesAvailable]="appoinrmentServicesAvailable"
    [cart]="cart"
    (changePaymentTypeEvent)="onChangePaymentType($event)"
    (couponFailedEvent)="onCouponFailedEvent($event)"
    (couponSuccessEvent)="onCouponSuccessEvent($event)"
    (resetStripeTokenEvent)="onResetStripeTokenEvent($event)"
    (stripeSuccessfulEvent)="onStripeSuccessfulEvent($event)"
    (paypalSuccessfulEvent)="onPaypalSuccessfulEvent($event)"
    (resetPaypalEvent)="onResetPaypalEvent($event)"
    (saferpaySuccessfulEvent)="onSaferpaySuccessfulEvent($event)"
    (resetSaferpayEvent)="onResetSaferpayEvent($event)"
  />
</ng-template>

<ng-template #cartOverviewTemplate let-mode="mode">
  <app-appointment-cart-overview
    [mode]="mode"
    (removeFromCartEvent)="removeFromCart($event, true, appointmentConstant.SUMMARY)"
    [(totalCartDuration)]="totalCartDuration"
    [(totalCartPrice)]="totalCartPrice"
    [(userState)]="userState"
    [appoinrmentServicesAvailable]="appoinrmentServicesAvailable"
    [cart]="cart"
    [coupon]="coupon"
    [finalCouponDiscount]="finalCouponDiscount"
    [lang]="lang"
    [partner]="partner"
    [courtesyForm]="courtesyForm"
    [widgetTemplates]="widgetTemplates"
    [appointmentState]="appointmentState"
    [conferenceState]="conferenceState"
    [stores]="stores"
    [globals]="globals"
    (updateCartEvent)="updateCart($event)"
  />
</ng-template>
