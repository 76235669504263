<button
  id="{{ id }}"
  type="button"
  [attr.aria-label]="text"
  class="{{ 'calenso-button ' + customCss }}"
  [disabled]="disabled"
  (click)="clickEvent.next('next')"
>
  @if (placement === 'right' || placement === 'left') {
    <span>{{ text }}</span>
  }

  @if (!placement && !icon) {
    <span>{{ text }}</span>
  }

  @if (placement === 'right') {
    <div class="next-arrow-container">
      <span>
        @if (isloading) {
          <div cardBody class="d-flex justify-content-center">
            <div class="spinner-border" role="status"></div>
          </div>
        } @else {
          <fa-icon class="icon cbw-next-arrow-button d-flex" [icon]="icon" />
        }
      </span>
    </div>
  }
</button>
