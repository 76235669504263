<ngb-accordion #accordion="ngbAccordion" [activeIds]="activePanels">
  @for (category of crossSellingServices; track category.appointmentServiceCategory.id; let index = $index) {
    <ngb-panel cardClass="calio-custom-accordion" id="static-{{ index }}">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between custom-accordion-header" (click)="accordion.toggle('static-' + index)">
          <div class="pe-3">
            <app-cw-multi-lang-title [isWordBreakEnabled]="false" [item]="category?.appointmentServiceCategory" lang="{{ lang }}" />
          </div>
          <div>
            <fa-icon class="icon" [icon]="opened ? 'chevron-up' : 'chevron-down'" />
          </div>
        </div>
      </ng-template>

      <ng-template ngbPanelContent>
        @for (service of category.aServicesOfCat; track service.id) {
          <div class="accordion-row cross-sales-service-accordion">
            <div class="service-row cbw-service-row">
              <div class="service-title cbw-service-title">
                <div class="words-break">
                  <app-cw-multi-lang-title
                    titleClass="cw-custom-title"
                    badgeLabel="{{ 'internal_label' | translate }}"
                    [item]="service"
                    [lang]="lang"
                    [showBadge]="service.is_internal === 1"
                  />
                </div>
                @if (partner.show_inline_description === 1) {
                  @if (service.description && service.description.trim() != '' && service.description != 'null' && service.description != null) {
                    <div class="cbw-service-description pe-2">
                      {{ service | translation: 'description': lang }}
                    </div>
                  }
                }
              </div>

              <div class="service-price-duration-block cbw-service-price-duration-block">
                <div class="cbw-service-duration">
                  <span>
                    @if ((service.duration | replaceComma | toHour) > 0) {
                      {{ service.duration | replaceComma | toHour }}{{ 'common.durationHour' | translate }}
                    } @else if ((service.duration | replaceComma | toMinute) > 0) {
                      {{ service.duration | replaceComma | toMinute}}{{ 'common.durationMinute' | translate }}
                    }
                  </span>
                </div>

                @if (service.price >= 0) {
                  <div class="cbw-service-price">
                    @if (service.price > 0) {
                      <div>
                        <span>
                          @if (service.is_price_from_activated) {
                            <span>
                              {{ 'common.price_from_label' | translate }}
                            </span>
                          }
                          {{ service.price | calioCurrency: partner['currency'] }}
                        </span>
                      </div>

                      @if (service?.tax?.rate > 0) {
                        <div class="cbw-taxes">
                          {{ 'tax_info.taxes' | translate: { percentage: service?.tax?.rate } }}
                        </div>
                      }
                    } @else if (service.price === 0) {
                      {{ 'free' | translate }}
                    }
                  </div>
                }
              </div>
            </div>

            <div class="add-service cbw-add-service">
              <calenso-button
                #button
                id="cbw-add-service-button"
                class="book-service-section-btn cbw-book-service-section-btn"
                customCss="calenso-secondary-outlined"
                placement="right"
                icon="circle-plus"
                [widgetColorConf]="widgetColorConf"
                [text]="(addCrossServiceToCartButtonText | translation: templateContent.TEMPLATE_CONTENT: lang)"
                (clickEvent)="addServiceToCart(service)"
              />
            </div>
          </div>
        }
      </ng-template>
    </ngb-panel>
  }
</ngb-accordion>
