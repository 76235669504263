import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WidgetColorConf } from '../../../models/widget-color.model';

@Component({
  selector: 'calenso-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule]
})
export class ButtonComponent {

  @Input() protected id: any;
  @Input() protected text = '';
  @Input() protected icon = 'chevron-circle-right';
  @Input() protected disabled: boolean;
  @Input() protected placement = 'left';
  @Input() protected customCss = '';
  @Input() protected isloading = false;
  // TODO(devs): This needs to be removed as not being used
  @Input() protected widgetColorConf: WidgetColorConf;

  @Output() protected clickEvent = new EventEmitter<any>();
}
